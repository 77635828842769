import AdminDashboard from "@Pages/Admin";
import AdminClassPage, {
  loader as adminClassPageLoader,
} from "@Pages/Admin/Class";
import AdminInstructorPage from "@Pages/Admin/Instructor/List";
import AuthPage from "@Pages/Auth";
import CreateClassPage from "@Pages/Class/Create/CreateClass";
import LearnClassPage, { loader as learnClassLoader } from "@Pages/Class/Learn";
import MaterialPage from "@Pages/Class/Learn/Material";
import PretestPage from "@Pages/Class/Learn/Pretest";
import InstructorDashboardPage from "@Pages/InstructorDashboard";
import InstructorActivityPage from "@Pages/InstructorDashboard/Activity";
import RulesPage from "@Pages/Class/Learn/Rules";
import LandingPage, { loader as getLandingPageData } from "@Pages/Landing";
import StudentDashboardPage from "@Pages/StudentDashboard";
import App from "App";
import InstructorDashboardClassPage, {
  loader as getClassDataInstructor,
} from "@Pages/InstructorDashboard/Class";
import InstructorActivityDetailPage from "@Pages/InstructorDashboard/Activity/ActivityDetail";
import AdminCreateInstructorPage from "@Pages/Admin/Instructor/Create";
import StudentDashboardMyClass, {
  loader as myClassLoader,
} from "@Pages/StudentDashboard/MyClass";
import StudentDashboardTransaction, {
  loader as studentTransactionLoader,
} from "@Pages/StudentDashboard/Transaction";
import StudentDashboardCertificate from "@Pages/StudentDashboard/Certificate";
import StudentDashboardSetting from "@Pages/StudentDashboard/Setting";
import TpmDetailPage, {
  loader as tpmDetailLoader,
} from "@Pages/InstructorDashboard/Activity/ActivityDetail/TpmDetail";
import SkillTestDetailPage from "@Pages/InstructorDashboard/Activity/ActivityDetail/SkilltestDetail";
import AdminTransactionPage from "@Pages/Admin/Transaction/List";
import AdminTransactionDetailsPage, {
  loader as adminTransactionDetailLoader,
} from "@Pages/Admin/Transaction/Details";
import InstructorProfilePage from "@Pages/InstructorDashboard/Profile";
import PosttestPage from "@Pages/Class/Learn/Posttest";
import UkPage from "@Pages/Class/Learn/Uk";
import JournalPage from "@Pages/Class/Learn/Journal";
import LearnCertificatePage from "@Pages/Class/Learn/Certificate";
import LandingClassDetailsPage, {
  loader as landingClassLoader,
} from "@Pages/LandingClassDetail";
import LandingPagePrakerja from "@Pages/LandingPagePrakerja";
import AdminClassDetailPage, {
  loader as adminClassLoader,
} from "@Pages/Admin/Class/ClassDetail";
import FaqPage from "@Pages/FAQ";
import CertificatePage, {
  loader as certificateLoader,
} from "@Pages/Certificate";
import AdminVocuherPages, {
  loader as getAllVoucherAdmin,
} from "@Pages/Admin/Voucher";
import AbsenceClassAdminPage, {
  loader as absenceClassLoader,
} from "@Pages/Admin/Class/AbsenceDetail";
import SchedulesPage from "@Pages/Class/Learn/Schedules";
import CallbackPrakerja from "@Pages/CallbackPrakerja";
import AdminPrakerjaPage, {
  loader as adminPrakerjaLoader,
} from "@Pages/Admin/Prakerja";
import AdminNotificationPage from "@Pages/Admin/Notification";
import EditClass, { loader as editClassLoader } from "@Pages/Class/Edit";
import OldCertificatePage from "@Pages/OldCertificate";
import CoursesPage from "@Pages/Courses";
import PrivacyPage from "@Pages/Privacy";
import AboutUsPage from "@Pages/AboutUs";
import PrakerjaStatusDetailPage, {
  loader as prakerjaStatusLoader,
} from "@Pages/Admin/Class/PrakerjaStatusDetail";
import ListStudentPage, {
  loader as getDataStudent,
} from "@Pages/Admin/Student/ListStudent";
import DetailStudentPage, {
  loader as getDataDetailStudent,
} from "@Pages/Admin/Student/DetailStudent";
import MasterDataCertificatePage, {
  loader as masterDataCertificateLoader,
} from "@Pages/Admin/Certificate";
import StudentClassDetailPage from "@Pages/Admin/Class/StudentClassDetail";
import CheckoutPage, { loader as fetchCourseDetail } from "@Pages/Checkout";
import DokuCallbackPage from "@Pages/DokuCallback";
import TransactionDetailPage, {
  loader as transactionDetailLoader,
} from "@Pages/StudentDashboard/TransactionDetail";
import ArticleLayout from "@Organism/Article";
import MainArticlePage, {
  loader as getAllDataArticles,
} from "@Pages/Article/Main";
import DetailArticlePage, {
  loader as getDataDetailArticle,
} from "@Pages/Article/Detail";
import AdminBlogPage from "@Pages/Admin/Blog";
import AdminPromoPage from "@Pages/Admin/Promo";
import BlogDetailPage from "@Pages/Admin/Blog/BlogDetail";
import BlogCreatePage from "@Pages/Admin/Blog/BlogCreate";
import BlogEditPage from "@Pages/Admin/Blog/BlogEdit";

const { createBrowserRouter, redirect } = require("react-router-dom");

const routes = [
  {
    path: "/",
    element: <App />,
    children: [
      { index: true, element: <LandingPage />, loader: getLandingPageData },
      {
        path: "checkout/:id",
        element: <CheckoutPage />,
        loader: fetchCourseDetail,
      },
      {
        path: "courses",
        element: <CoursesPage />,
      },
      {
        path: "privacy",
        element: <PrivacyPage />,
      },
      {
        path: "about",
        element: <AboutUsPage />,
      },
      {
        path: "article",
        element: <ArticleLayout />,
        children: [
          {
            index: true,
            element: <MainArticlePage />,
            loader: getAllDataArticles,
          },
          {
            path: ":id",
            element: <DetailArticlePage />,
            loader: getDataDetailArticle,
          },
        ],
      },
      { path: "prakerja", element: <LandingPagePrakerja /> },
      { path: "faq", element: <FaqPage /> },
      {
        path: "course/:name/:id",
        loader: landingClassLoader,
        element: <LandingClassDetailsPage />,
      },
      { path: "login", element: <AuthPage type={"login"} /> },
      { path: "register", element: <AuthPage type={"register"} /> },
      {
        path: "student",
        element: <StudentDashboardPage />,
        children: [
          {
            index: true,
            element: <StudentDashboardMyClass />,
            loader: myClassLoader,
          },
          {
            path: "transaction",
            element: <StudentDashboardTransaction />,
            loader: studentTransactionLoader,
          },
          {
            path: "transaction/:id",
            element: <TransactionDetailPage />,
            loader: transactionDetailLoader,
          },
          {
            path: "certificate",
            element: <StudentDashboardCertificate />,
          },
          {
            path: "profile",
            element: <StudentDashboardSetting />,
          },
        ],
      },
      {
        path: "instructor",
        element: <InstructorDashboardPage />,
        children: [
          {
            index: true,
            loader: () => {
              throw redirect("activity");
            },
          },
          {
            path: "activity",
            children: [
              {
                index: true,
                path: "",
                element: <InstructorActivityPage />,
                loader: getClassDataInstructor,
              },
              {
                path: ":id",
                // element: <InstructorActivityDetailPage />,
                children: [
                  {
                    index: "true",
                    element: <InstructorActivityDetailPage />,
                  },
                  {
                    path: ":progress/tpm",
                    element: <TpmDetailPage />,
                    loader: tpmDetailLoader,
                  },
                  {
                    path: ":progress/skilltest",
                    element: <SkillTestDetailPage />,
                    loader: tpmDetailLoader,
                  },
                ],
              },
              {
                path: ":id/",
                element: <InstructorActivityDetailPage />,
              },
            ],
          },
          {
            path: "class",
            element: <InstructorDashboardClassPage />,
            loader: getClassDataInstructor,
          },
          {
            path: "profile",
            element: <InstructorProfilePage />,
          },
        ],
      },
      {
        path: "admin",
        element: <AdminDashboard />,
        children: [
          {
            index: true,
            loader: () => {
              throw redirect("class");
            },
          },
          {
            path: "class",
            children: [
              {
                index: true,
                element: <AdminClassPage />,
                loader: adminClassPageLoader,
              },
              {
                path: "new",
                children: [
                  { path: "", element: <CreateClassPage /> },
                  {
                    path: "offline",
                    element: <CreateClassPage type="OFFLINE" />,
                  },
                  {
                    path: "webinar",
                    element: <CreateClassPage type="WEBINAR" />,
                  },
                  { path: "video", element: <CreateClassPage type="VIDEO" /> },
                  {
                    path: "workshop",
                    element: <CreateClassPage type="WORKSHOP" />,
                  },
                ],
              },
              {
                path: "edit",
                children: [
                  {
                    path: "offline/:id",
                    element: <EditClass type="OFFLINE" />,
                    loader: editClassLoader,
                  },
                  {
                    path: "webinar/:id",
                    element: <EditClass type="WEBINAR" />,
                    loader: editClassLoader,
                  },
                  {
                    path: "video/:id",
                    element: <EditClass type="VIDEO" />,
                    loader: editClassLoader,
                  },
                ],
              },
              {
                path: ":id",
                element: <AdminClassDetailPage />,
                loader: adminClassLoader,
              },
              {
                path: ":courseID/student/:userID",
                element: <StudentClassDetailPage />,
              },
              {
                path: ":id/absence",
                element: <AbsenceClassAdminPage />,
                loader: absenceClassLoader,
              },
              {
                path: ":id/status",
                element: <PrakerjaStatusDetailPage />,
                loader: prakerjaStatusLoader,
              },
            ],
          },
          {
            path: "student",
            children: [
              {
                index: true,
                element: <ListStudentPage />,
                loader: getDataStudent,
              },
              {
                path: "detail/:id",
                element: <DetailStudentPage />,
                loader: getDataDetailStudent,
              },
            ],
          },
          {
            path: "blog",
            children: [
              {
                index: true,
                element: <AdminBlogPage />,
              },
              {
                path: "create",
                element: <BlogCreatePage />,
              },
              {
                path: ":id/edit",
                element: <BlogEditPage />,
              },
              {
                path: ":id",
                element: <BlogDetailPage />,
              },
            ],
          },
          {
            path: "instructor",
            children: [
              {
                index: true,
                element: <AdminInstructorPage />,
              },
              {
                path: "new",
                element: <AdminCreateInstructorPage />,
              },
              {
                path: "edit/:id",
                element: <AdminCreateInstructorPage isEdit={true} />,
              },
            ],
          },
          {
            path: "voucher",
            element: <AdminVocuherPages />,
            loader: getAllVoucherAdmin,
          },
          {
            path: "promo",
            element: <AdminPromoPage />,
          },
          {
            path: "transaction",
            children: [
              {
                index: true,
                element: <AdminTransactionPage />,
              },
              {
                path: ":id",
                element: <AdminTransactionDetailsPage />,
                loader: adminTransactionDetailLoader,
              },
            ],
          },
          {
            path: "prakerja",
            element: <AdminPrakerjaPage />,
            loader: adminPrakerjaLoader,
          },
          {
            path: "notification",
            element: <AdminNotificationPage />,
          },
          {
            path: "certificate",
            element: <MasterDataCertificatePage />,
            loader: masterDataCertificateLoader,
          },
        ],
      },
      {
        path: "classes",
        loader: () => {
          if (!localStorage?.getItem("accessToken")) {
            throw redirect("/login");
          }
          return null;
        },
        children: [
          {
            path: "learn/:id",
            element: <LearnClassPage />,
            loader: learnClassLoader,
            children: [
              {
                index: true,
                element: <PretestPage />,
                loader: learnClassLoader,
              },
              {
                path: "pretest",
                element: <PretestPage />,
                loader: learnClassLoader,
              },
              {
                path: "posttest",
                element: <PosttestPage />,
                loader: learnClassLoader,
              },
              {
                path: "uk",
                element: <UkPage />,
                loader: learnClassLoader,
              },
              {
                path: "rules",
                element: <RulesPage />,
                loader: learnClassLoader,
              },
              {
                path: "schedules",
                element: <SchedulesPage />,
                loader: learnClassLoader,
              },
              {
                path: "journal",
                element: <JournalPage />,
                loader: learnClassLoader,
              },
              {
                path: "certificate",
                element: <LearnCertificatePage />,
                loader: learnClassLoader,
              },
              {
                path: ":materialCode",
                loader: learnClassLoader,
                element: <MaterialPage />,
              },
            ],
          },
          {
            path: "new",
            children: [
              { path: "", element: <CreateClassPage /> }, // Assuming CreateClassPage should be rendered for "/classes/new"
              { path: "offline", element: <CreateClassPage type="OFFLINE" /> },
              { path: "webinar", element: <CreateClassPage type="WEBINAR" /> },
              { path: "video", element: <CreateClassPage type="VIDEO" /> },
              {
                path: "workshop",
                element: <CreateClassPage type="WORKSHOP" />,
              },
            ],
          },
          {
            path: "edit",
            children: [
              {
                path: "offline/:id",
                element: <EditClass type="OFFLINE" />,
                loader: editClassLoader,
              },
              {
                path: "webinar/:id",
                element: <EditClass type="WEBINAR" />,
                loader: editClassLoader,
              },
              {
                path: "video/:id",
                element: <EditClass type="VIDEO" />,
                loader: editClassLoader,
              },
              {
                path: "workshop/:id",
                element: <EditClass type="WORKSHOP" />,
                loader: editClassLoader,
              },
            ],
          },
        ],
      },
      {
        path: "callback-prakerja",
        element: <CallbackPrakerja />,
      },
    ],
  },
  {
    path: "checkout-status",
    element: <DokuCallbackPage />,
  },
  {
    path: "certificate/:id",
    element: <CertificatePage />,
    loader: certificateLoader,
  },
  {
    path: "certificate",
    element: <OldCertificatePage />,
  },
];

const router = createBrowserRouter(routes);

export default router;
