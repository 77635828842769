// eslint-disable-next-line import/no-anonymous-default-export
export default {
  LOGO: require("@Assets/Images/logo.png"),
  SHADOWED_LOGO: require("@Assets/Images/shadowed-logo.png"),
  WEBINAR: require("@Assets/Images/webinar.png"),
  OFFLINE: require("@Assets/Images/offline.png"),
  WEBINAR_DISABLED: require("@Assets/Images/WEBINAR_DISABLED.png"),
  OFFLINE_DISABLED: require("@Assets/Images/OFFLINE_DISABLED.png"),
  VIDEO: require("@Assets/Images/video.png"),
  WORKSHOP: require("@Assets/Images/Workshop.png"),
  LEFT_CLAY: require("@Assets/Images/left-clay.png"),
  RIGHT_CLAY: require("@Assets/Images/right-clay.png"),
  DOODLE_BG: require("@Assets/Images/doodle-bg.png"),
  BOOK: require("@Assets/Images/book 1.png"),
  ID_CARD: require("@Assets/Images/id-card.png"),
  PROFILE_OVERLAY: require("@Assets/Images/profile-overlay.png"),
  PDF_ICON: require("@Assets/Images/pdf-icon.png"),
  EXCEL_ICON: require("@Assets/Images/excel-icon.png"),
  DOC_ICON: require("@Assets/Images/doc-icon.png"),
  LOGO_PRAKERJA: require("@Assets/Images/logo-prakerja.png"),
  LOGO_PRAKERJA_NEW: require("@Assets/Images/logo-prakerja-new.png"),
  WHEEL_LANDING_PAGE: require("@Assets/Images/wheel.png"),
  FAQ_LOGO_LANDING_PAGE_L: require("@Assets/Images/faq_left_landing_page.png"),
  FAQ_LOGO_LANDING_PAGE_R: require("@Assets/Images/faq_right_landing_page.png"),
  CLASS_PRAKERJA_LANDING_PAGE: require("@Assets/Images/classPrakerjaImage.png"),
  CLASS_REGULAR_LANDING_PAGE: require("@Assets/Images/classRegular.png"),
  PARCEL_BOX: require("@Assets/Images/parcel_box.png"),
  MAP_LANDING_PAGE: require("@Assets/Images/map_carousel-landing_page.png"),
  NOTES_LANDING_PAGE: require("@Assets/Images/notes.png"),
  PERCENTAGE_LANDING_PAGE: require("@Assets/Images/percentage.png"),
  GLOBE_LANDING_PAGE: require("@Assets/Images/globe.png"),
  DESIGN_LANDING_PAGE: require("@Assets/Images/desain.png"),
  ART_LANDING_PAGE: require("@Assets/Images/art.png"),
  LIFE_STYLE_LANDING_PAGE: require("@Assets/Images/lifeStyle.png"),
  MARKETING_LANDING_PAGE: require("@Assets/Images/marketing.png"),
  HEADPHONE_LANDING_PAGE: require("@Assets/Images/headphone.png"),
  CLAY_DOODLE_CARD: require("@Assets/Images/clayCardDoodle.png"),
  STUDENT_DOODLE_ICON: require("@Assets/Images/studentIconDoodle.png"),
  MONEY_DOCUMENT: require("@Assets/Images/money-document.png"),
  BCA_LOGO: require("@Assets/Images/bca-logo.png"),
  PUBLISH_COURSE_LOADING: require("@Assets/Videos/publish-course-loading.mp4"),
  MAC: require("@Assets/Images/mac.png"),
  INSTRUCTOR_BG: require("@Assets/Images/instructor-bg.png"),
  SUCCESS_COIN: require("@Assets/Images/coin-success.png"),
  DAFTAR_PENILAIAN_UMUM: require("@Assets/certificate/daftar_penilaian_umum.jpeg"),
  DAFTAR_PENILAIAN: require("@Assets/certificate/daftar_penilaian.jpeg"),
  KELULUSAN_BUKALAPAK_BAIK: require("@Assets/certificate/kelulusan_bukalapak_baik.jpeg"),
  KELULUSAN_BUKALAPAK: require("@Assets/certificate/kelulusan_bukalapak.jpeg"),
  KELULUSAN_TOKOPEDIA_BAIK: require("@Assets/certificate/kelulusan_tokopedia_baik.jpeg"),
  KELULUSAN_TOKOPEDIA: require("@Assets/certificate/kelulusan_tokopedia.jpeg"),
  KELULUSAN_PINTAR_BAIK: require("@Assets/certificate/kelulusan_pintar_baik.jpeg"),
  KELULUSAN_PINTAR: require("@Assets/certificate/kelulusan_pintar.jpeg"),
  KELULUSAN_UMUM: require("@Assets/certificate/kelulusan_Umum.jpeg"),
  KELULUSAN_UMUM_BAIK: require("@Assets/certificate/kelulusan_Umum_baik.jpeg"),
  LOCATION: require("@Assets/Images/location.png"),
  CALENDAR_ACTIVE: require("@Assets/Images/calendarClassActive.png"),
  CALENDAR_INACTIVE: require("@Assets/Images/calendarClassNoActve.png"),
  CALLBACK_VECTOR: require("@Assets/Images/callback-vector.png"),
  CHECKLIST: require("@Assets/Images/checklist.png"),
  REJECTED: require("@Assets/Images/Rejected.png"),
  IMAGE_THUMBNAIL: require("@Assets/Images/image-thumbnail.png"),
  WARNING: require("@Assets/Images/icon-container.png"),
  PC_LAYOUT: require("@Assets/Images/pc_layout.png"),
  SESSION_DISABLE: require("@Assets/Images/disable-session.png"),
  PRIVACY: require("@Assets/Images/privacy.png"),
  STRUCTURE: require("@Assets/Images/struktur.png"),
  MOCKUP_IMAGE: require("@Assets/Images/mockup_image.png"),
  CERTIFICATE_EMPTY: require("@Assets/Images/certificate-empty.png"),
  CERTIFICATE_VECTOR: require("@Assets/Images/certificate-vector.png"),
  CERTIFICATE_INPUT: require("@Assets/Images/input-certificate.jpg"),
  LOADING_CLOCK: require("@Assets/Images/loading-clock.png"),
  SUCCESS_SHIELD: require("@Assets/Images/success-shield.png"),
  NO_BATCH: require("@Assets/Images/no-batch.png"),
  SUCCESS_MODAL: require("@Assets/Images/success-modal.png"),
  LOADER_GRADIENT: require("@Assets/Images/Loader.png"),
  LINK: require("@Assets/Images/link.png"),
  LINE: require("@Assets/Images/line.png"),
  WHATSAPP: require("@Assets/Images/whatsApp.png"),
  X: require("@Assets/Images/x.png"),
  FACEBOOK: require("@Assets/Images/facebook.png"),
  TELEGRAM: require("@Assets/Images/telegram.png"),
};
